import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../helpers';
import { useIntegrationContext } from '../../ModalContexts/IntegrationsModalsContext';
export interface IntegrationItemProps {
  id: string;
  user_name: string;
  api_token: string;
  type: string;
  description: string;
  website: string;
  enabled: boolean;
}
const icons: { intergrationType: string; icon: string }[] = [
  {
    icon: toAbsoluteUrl('/media/integration/wordpress.svg'),
    intergrationType: 'WORDPRESS',
  },
  {
    icon: toAbsoluteUrl('/media/integration/prestashop.svg'),
    intergrationType: 'PRESTASHOP',
  },
  {
    icon: toAbsoluteUrl('/media/integration/webapp.svg'),
    intergrationType: 'WEBAPP',
  },
];
const geticonFromType = (type: string) => {
  return (
    icons.find((item) => item.intergrationType === type)?.icon ||
    toAbsoluteUrl('/media/logos/logo.svg')
  );
};

function IntegrationItem({ item }: { item: IntegrationItemProps }) {
  const intl = useIntl();
  const [showSecret, setShowSecret] = useState(false);
  const currentIcon = useMemo(() => geticonFromType(item.type), [item.type]);
  const { handleShowEditIntegration } = useIntegrationContext();
  const toggleSecret = () => {
    setShowSecret(!showSecret);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(item.api_token);
    alert('Secret key copied to clipboard!');
  };
  return (
    <div className='card hover-elevate-up shadow-sm parent-hover'>
      <div className='card-header'>
        <div className='d-flex align-items-center gap-3 flex-grow-1'>
          <div className='symbol symbol-50px'>
            <img src={currentIcon} alt='' />
          </div>
          <div className='d-flex flex-column flex-grow-1'>
            <div className='card-title fs-5 fw-bold'>
              <FormattedMessage
                id={`SETTINGS.INTEGRATION.TYPE.${item.type}`}
                defaultMessage={item.type}
              />
            </div>
            <div className='card-subtitle text-gray-500 fs-6 fw-semibold'>
              {item.website || '--'}
            </div>
          </div>
        </div>
        <div className='card-toolbar d-flex gap-2'>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input className='form-check-input' type='checkbox' checked={item.enabled} />
          </div>
          <button
            className='btn btn-icon btn-light'
            onClick={() => {
              handleShowEditIntegration(item);
            }}>
            <i className=' fa-solid fa-pencil'></i>
          </button>
        </div>
      </div>
      <div className='card-body d-flex align-items flex-column gap-8'>
        <div className='d-flex align-items-center w-100 '>
          <div className='input-group input-group-solid'>
            <input
              type={showSecret ? 'text' : 'password'}
              className='form-control text-center'
              value={item.api_token}
              disabled
              readOnly
            />
            <div className='input-group-text cursor-pointer' onClick={toggleSecret}>
              <i className={`fas ${showSecret ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </div>
            <div className='input-group-text cursor-pointer' onClick={copyToClipboard}>
              <i className='fas fa-copy'></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntegrationItem;
