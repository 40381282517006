import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getListIntegration } from '../../../../setup/redux/effects/settings/Integration';
import { useIntegrationContext } from '../../../ModalContexts/IntegrationsModalsContext';
import IntegrationItem from '../../../components/IntegrationItem/IntegrationItem';
import Loader from '../../../components/Loader/Loader';

function Integration() {
  const { data: listIntegration, isLoading } = useQuery({
    queryKey: ['integrations'],
    queryFn: () => getListIntegration().then((res) => res.data),
  });

  const { handleShowAddIntegration } = useIntegrationContext();

  return (
    <Loader loading={isLoading}>
      <div className='card h-100 sw-settings-content'>
        <div className='card-header sw-settings-header'>
          <div className='sw-settings-title-container'>
            <div className='sw-settings-title-icon'>
              <i className='fs-1 fa-light fa-file-invoice-dollar'></i>
            </div>
            <div className='sw-settings-title'>
              <FormattedMessage id='TOPBAR.SETTINGS.INTEGRATION' />
            </div>
          </div>
          <div className='sw-settings-toolbar'>
            <div className='separator'></div>
            <div
              className='btn btn-sm btn-sw-primary'
              onClick={() => {
                handleShowAddIntegration();
              }}>
              <i className='fas fa-plus'></i>
              <FormattedMessage id='TOOLBAR.BREADCRUMBS.ADD' />
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='d-flex align-items-center rounded py-5 px-5 bg-light-primary mb-4'>
            <i className='fa-duotone fa-circle-question fs-3x text-primary me-5'></i>
            <div className='text-gray-700 fw-bold fs-6'>
              <FormattedMessage
                id='INTEGRATION.MESSAGE'
                values={{
                  link: (
                    <a
                      href={'https://docs.swiver.io'}
                      target='_blank'
                      rel='noreferrer'
                      className='text-primary'>
                      docs.swiver.io
                    </a>
                  ),
                }}
              />
            </div>
          </div>
          <div className='row g-4'>
            {listIntegration?.map((item: any, index) => (
              <div className='col-6' key={index}>
                <IntegrationItem item={item}></IntegrationItem>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Loader>
  );
}

export default Integration;
