import clsx from 'clsx';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { RootState } from '../../../setup/Store';
import { signOut } from '../../../setup/redux/reducers/AuthReducer';
import Guard, {
  authorizeOnRoleOrPermissionAndPermissionPlan,
} from '../../components/Security/Guard';
import { SuspensedView } from '../../components/SuspendedView';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
import { DrawerComponent } from '../../utils/ts/components';
import Integration from './Integration/Integration';
import IntegrationProvider from '../../ModalContexts/IntegrationsModalsContext';

const BankModalProvider = lazyWithRetry(() => import('../../ModalProviders/BankModal'));
const AddProductModalsProvider = lazyWithRetry(
  () => import('../Products/AddProduct/AddProductModalsContext')
);
const DocumentSettings = lazyWithRetry(() => import('./Document/DocumentSettings'));
const Priceslist = lazyWithRetry(() => import('./PricesList/Priceslist'));
const TvaAndTax = lazyWithRetry(() => import('./TaxesAndVats'));
const UserManagement = lazyWithRetry(() => import('./Users'));
const AddOrEditUser = lazyWithRetry(() => import('./UserManagement/AddOrEditUser/AddOrEditUser'));
const CompanySettings = lazyWithRetry(() => import('./company/CompanySettings'));
const Banks = lazyWithRetry(() => import('./Banks/Banks'));
const Accounting = lazyWithRetry(() => import('./Accounting/Accounting'));
const Projects = lazyWithRetry(() => import('../Projects'));
const BrandsCategoriesAndUnits = lazyWithRetry(
  () => import('./BrandsCategoriesAndUnits/BrandsCategoriesAndUnits')
);
export default function SettingsRoutes() {
  return (
    <Routes>
      <Route element={<SettingsMasterLayout />}>
        <Route path='*' element={<Navigate to='/settings/company' />} />
        <Route
          path='/banks'
          element={
            <SuspensedView>
              <BankModalProvider>
                <Banks />
              </BankModalProvider>
            </SuspensedView>
          }
        />
        <Route
          path='/documents'
          element={
            <SuspensedView>
              <BankModalProvider>
                <DocumentSettings />
              </BankModalProvider>
            </SuspensedView>
          }
        />
        <Route
          path='/company'
          element={
            <SuspensedView>
              <CompanySettings />
            </SuspensedView>
          }
        />
        <Route
          path='/vats-and-taxes'
          element={
            <SuspensedView>
              <AddProductModalsProvider>
                <TvaAndTax />
              </AddProductModalsProvider>
            </SuspensedView>
          }
        />

        <Route
          path='/prices-list'
          element={
            <SuspensedView>
              <Priceslist />
            </SuspensedView>
          }
        />
        <Route
          path='/accounting'
          element={
            <SuspensedView>
              <Accounting />
            </SuspensedView>
          }
        />
        <Route
          path='/users'
          element={
            <Guard
              planPermissions={['userManagement']}
              permissions={['Accounting']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <UserManagement />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/integration'
          element={
            <Guard
              planPermissions={['userManagement']}
              permissions={['Accounting']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <IntegrationProvider>
                  <Integration />
                </IntegrationProvider>
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/projects'
          element={
            <SuspensedView>
              <Projects />
            </SuspensedView>
          }
        />
        <Route
          path='/brands-and-categories'
          element={
            <SuspensedView>
              <BrandsCategoriesAndUnits />
            </SuspensedView>
          }
        />
        <Route
          path='/users/add'
          element={
            <Guard
              planPermissions={['userManagement']}
              permissions={['Accounting']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddOrEditUser />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/users/edit/:id'
          element={
            <SuspensedView>
              <Guard
                planPermissions={['userManagement']}
                permissions={['Accounting']}
                roles={['ROLE_ADMIN']}
                authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
                alt={<Navigate to='/unauthorized' replace={true} />}>
                <AddOrEditUser mode='edit' />
              </Guard>
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
}
const SettingsMasterLayout = () => {
  const { formatMessage, formatDate } = useIntl();

  const links = [
    {
      to: '/settings/company',
      name: 'TOPBAR.SETTINGS.COMPANYPROFILE',
      icon: 'fs-1 fa-light fa-buildings',
    },
    {
      to: '/settings/documents',
      name: 'TOPBAR.SETTINGS.DOCUMENTSSETTINGS',
      icon: 'fs-1 fa-light fa-file-circle-check',
    },
    {
      to: '/settings/banks',
      name: 'TOPBAR.SETTINGS.BANKACCOUNT',
      icon: 'fs-1 fa-light fa-building-columns',
    },
    {
      to: '/settings/vats-and-taxes',
      name: 'TOPBAR.SETTINGS.TAXESANDVTA',
      icon: 'fs-1 fa-light fa-file-invoice-dollar',
    },
    {
      to: '/settings/prices-list',
      name: 'PRICES.LIST',
      icon: 'fs-1 fa-light fa-money-check-dollar-pen',
    },
    {
      to: '/settings/accounting',
      name: 'TOPBAR.SETTINGS.ACCOUNTANT',
      icon: 'fs-1 fa-light fa-chart-user',
    },
    {
      to: '/settings/users',
      name: 'TOPBAR.SETTINGS.USERSANDROLES',
      icon: 'fs-1 fa-light fa-users',
    },
    {
      to: '/settings/integration',
      name: 'TOPBAR.SETTINGS.INTEGRATION',
      icon: 'fs-1 fa-light fa-users',
    },
    {
      to: '/settings/projects',
      name: 'TOPBAR.SETTINGS.PROJECTS',
      icon: 'fs-1 fa-light fa-folders',
    },
    {
      to: '/settings/brands-and-categories',
      name: 'TOPBAR.SETTINGS.BRANDS_CATEGORIES_AND_UNITS',
      icon: 'fs-1 fa-light fa-copyright',
    },
  ];
  const user = useSelector((state: RootState) => state.auth.user);
  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
  };
  return (
    <>
      <div className='row g-4 h-md-100'>
        <div className='sw-settings-sidebar col-md-4 col-xl-3'>
          <div className='sw-settings-sidebar-header'>
            <div className='sw-settings-sidebar-title-container'>
              <div className='sw-settings-sidebar-title'>
                <FormattedMessage
                  id='COMMON.KEY_VALUE'
                  values={{
                    key: formatMessage({ id: 'PROFILE.PLAN' }),
                    value: formatMessage({
                      id: `PLANS.${user?.current_plan?.name?.toUpperCase()}`,
                    }),
                  }}
                />
              </div>
              <div className='sw-settings-sidebar-subtitle'>
                <FormattedMessage
                  id='PROFILE.EXPIRATION.SHORT'
                  values={{
                    date: formatDate(user?.subscription_expiry, {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    }),
                  }}
                />
              </div>
            </div>
            <div className='sw-settings-sidebar-toolbar'>
              <i
                className='d-md-none fa-light fa-ellipsis-vertical fs-2x'
                id='sw_engage_settings_toggle'
              />
            </div>
          </div>
          <div className='sw-settings-list d-none d-md-flex'>
            <div className='sw-settings-company-name'>{user?.current_company.name}</div>
            {links.map((link, index) => (
              <NavLink
                to={link.to}
                key={index}
                className={(status) => clsx('sw-settings-item', status.isActive && 'active')}>
                <div className='sw-settings-bullet'></div>
                <i className={link.icon} /> <FormattedMessage id={link.name} />
              </NavLink>
            ))}
          </div>
        </div>

        <div className='col-md-8 col-xl-9 min-h-100 overflow-y-auto'>
          <Outlet />
        </div>
      </div>
      <div
        id='sw_engage_settings'
        className='bg-body pt-4'
        data-kt-drawer='true'
        data-kt-drawer-name='explore'
        data-kt-drawer-activate='{"default": true,"md":false}'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width='80%'
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#sw_engage_settings_toggle'
        data-kt-drawer-close='#sw_engage_settings_close'>
        <div className='sw-settings-list'>
          <div className='sw-settings-company-name'>{user?.current_company.name}</div>
          {links.map((link, index) => (
            <NavLink
              to={link.to}
              key={index}
              className={(status) => clsx('sw-settings-item', status.isActive && 'active')}>
              <div className='sw-settings-bullet'></div>
              <i className={link.icon} /> <FormattedMessage id={link.name} />
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};
