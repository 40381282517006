import axios from 'axios';
import { IntegrationItemProps } from '../../../../app/components/IntegrationItem/IntegrationItem';
import type { DefaultIntegrationFormValuesType } from '../../../../app/ModalContexts/IntegrationsModalsContext';

export function getListIntegration() {
  return axios.get<IntegrationItemProps[]>('/secure/user/user-integrations');
}

export function createIntegration(data: DefaultIntegrationFormValuesType) {
  return axios.post('/secure/user/user-integration', data);
}
