import { useQueryClient } from '@tanstack/react-query';
import { createContext, FC, useContext, useRef, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { createIntegration } from '../../setup/redux/effects/settings/Integration';
import CustomRadio from '../components/CustomRadio/CustomRadio';
const defaultFormValues = {
  id: undefined as string | number | undefined,
  user_name: '',
  type: 'WORDPRESS',
  description: '',
  website: '',
  api_token: '',
  enabled: true,
};

const integrationTypes = ['WORDPRESS', 'PRESTASHOP', 'WEBAPP'] as const;

export type IntegrationType = (typeof integrationTypes)[number];

export type DefaultIntegrationFormValuesType = typeof defaultFormValues;

export const useIntegrationContext = () => useContext(IntegrationContext);

const IntegrationContext = createContext<{
  handleShowAddIntegration: (onSuccess?: () => void) => void;
  handleShowEditIntegration: (
    integration: DefaultIntegrationFormValuesType,
    onSuccess?: () => void
  ) => void;
  handleHideIntegration: () => void;
}>({
  handleShowAddIntegration: () => {},
  handleShowEditIntegration: () => {},
  handleHideIntegration: () => {},
});

const IntegrationProvider: FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const {
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({ defaultValues: defaultFormValues });
  const onSuccessRef = useRef<() => void>();
  const [editId, setEditId] = useState<number | string>();
  const [isModalShown, setIsModalShown] = useState(false);

  const handleShowAddIntegration = (onSuccess?: () => void) => {
    onSuccessRef.current = onSuccess;
    reset(defaultFormValues);
    setIsModalShown(true);
  };

  const handleShowEditIntegration = (
    integration: DefaultIntegrationFormValuesType,
    onSuccess?: () => void
  ) => {
    setEditId(undefined);
    onSuccessRef.current = onSuccess;
    reset(integration);
    setIsModalShown(true);
  };

  const handleHideIntegration = () => {
    setEditId(undefined);
    onSuccessRef.current = undefined;
    reset(defaultFormValues);
    setIsModalShown(false);
  };

  const onSubmit = async (data: DefaultIntegrationFormValuesType) => {
    if (isSubmitting) return;
    if (editId) {
      // Edit integration
    } else {
      try {
        await createIntegration(data);
        onSuccessRef.current?.();
        queryClient.invalidateQueries({ queryKey: ['integrations'] });
        handleHideIntegration();
      } catch (error) {
        toast.error(formatMessage({ id: 'TOAST.ERROR' }));
      }
    }
  };

  return (
    <IntegrationContext.Provider
      value={{
        handleShowAddIntegration,
        handleShowEditIntegration,
        handleHideIntegration,
      }}>
      {children}
      <Modal show={isModalShown} onHide={handleHideIntegration} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='integration.title' />
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} className='row g-4'>
            <Controller
              name='user_name'
              control={control}
              render={({ field, fieldState }) => (
                <div>
                  <div className='form-label'>
                    <FormattedMessage id='FORM.FIELD.NAME' />
                  </div>
                  <input type='text' className='form-control form-control-solid' {...field} />
                  {fieldState.error && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <FormattedMessage
                          id={fieldState?.error?.message}
                          defaultMessage={fieldState?.error?.message}
                          values={{ field: <FormattedMessage id='FORM.FIELD.NAME' /> }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
            <Controller
              name='type'
              control={control}
              render={({ field, fieldState }) => (
                <div>
                  <div className='form-label'>
                    <FormattedMessage id='FORM.FIELD.TYPE' />
                  </div>
                  <div className='row g-2'>
                    {integrationTypes.map((integrationType, index) => (
                      <div className='col-md-4' key={index}>
                        <CustomRadio
                          name={field.name}
                          value={integrationType}
                          checked={field.value === integrationType}
                          onChange={field.onChange}>
                          <FormattedMessage
                            id={`SETTINGS.INTEGRATION.TYPE.${integrationType}`}
                            defaultMessage={integrationType}
                          />
                        </CustomRadio>
                      </div>
                    ))}
                  </div>
                  {fieldState.error && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <FormattedMessage
                          id={fieldState?.error?.message}
                          defaultMessage={fieldState?.error?.message}
                          values={{ field: <FormattedMessage id='FORM.FIELD.TYPE' /> }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
            <Controller
              name='description'
              control={control}
              render={({ field, fieldState }) => (
                <div>
                  <div className='form-label'>
                    <FormattedMessage id='FORM.FIELD.DESCRIPTION' />
                  </div>
                  <textarea className='form-control form-control-solid' {...field} />
                  {fieldState.error && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <FormattedMessage
                          id={fieldState?.error?.message}
                          defaultMessage={fieldState?.error?.message}
                          values={{ field: <FormattedMessage id='FORM.FIELD.DESCRIPTION' /> }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
            <Controller
              name='website'
              control={control}
              render={({ field, fieldState }) => (
                <div>
                  <div className='form-label'>
                    <FormattedMessage id='FORM.FIELD.WEBSITE' />
                  </div>
                  <input type='text' className='form-control form-control-solid' {...field} />
                  {fieldState.error && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <FormattedMessage
                          id={fieldState?.error?.message}
                          defaultMessage={fieldState?.error?.message}
                          values={{ field: <FormattedMessage id='FORM.FIELD.WEBSITE' /> }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
          </form>
        </ModalBody>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={handleHideIntegration}>
            <FormattedMessage id='COMMON.CANCEL' />
          </button>
          <button
            type='button'
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
            className='btn btn-primary'>
            <FormattedMessage id='COMMON.SAVE' />
          </button>
        </Modal.Footer>
      </Modal>
    </IntegrationContext.Provider>
  );
};

export default IntegrationProvider;
